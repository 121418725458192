
export const IsAdmin = (roles: string | string[], role: string) => {
    if (!roles) {
        return false;
    }

    if (Array.isArray(roles)) {
        const idx = roles.findIndex((x) => x === 'Administrator');
        return idx !== -1;
    } else {
        return roles === 'Administrator';
    }
};