
import React, { useContext, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { Fab, Grid, Badge, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Chip, Stack } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';


import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { IItem } from '../../interfaces/IItem';
import { ITypePrice } from '../../interfaces/ITypePrice';


// const showRest = process.env.REACT_APP_SHOWREST;

interface IProps {
    item: IItem
    categoryImg: string,
    customerPriceType: ITypePrice,
    showRest: boolean
}

const host = process.env.REACT_APP_IMG_ROOT;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            borderRadius: 6,
            // backgroundColor: 'rgb(255, 255, 255)',
            // color: 'rgba(58, 53, 65, 0.87)',
            transition: 'box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px',
            position: 'relative'
        },

        action_top: {
            // backgroundColor: '#282e31',
            paddingBottom: theme.spacing(1),
        },
        media: {
            marginTop: theme.spacing(1),
            height: theme.spacing(23),
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: theme.spacing(19),
        },
        progress: {
            height: 2,
            // position: "absolute",
            // top: 0,
            // left: 0,
            // right: 0,
        },
        prices: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        priceRC: {
            fontSize: '1.4rem'
        },
        superscript: {
            position: 'relative',
            marginLeft: 2,
            top: '-0.8em',
            fontSize: '60%',
        },

        subscript: {
            position: 'relative',
            marginLeft: -22,
            fontSize: '24%',
        },
        subscript2: {
            position: 'relative',
            marginLeft: -14,
            fontSize: '26%',
        },

        groupIcon: {
            fontSize: '2.125rem',
            lineHeight: '1.235',
            color: 'primary'
        },
        cat_img: {
            fontSize: 130,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        },
        rest: {
            position: 'absolute',
            top: 188,
            right: 72,
            zIndex: 1,
        },
        buy: {
            position: 'absolute',
            top: 170,
            right: 8,
            zIndex: 1,
        }

    })
);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export const ProductThum = ({ item, categoryImg, customerPriceType, showRest }: IProps) => {
    const classes = useStyles();
    const history = useHistory();
    const auth = useAuth();
    const { addRow, contract, order } = useContext(AppContext);
    const [disableAdd, setDisavleAdd] = useState(true);

    const handleAddToShoppingCart = () => {
        addRow(item, 1);
    };

    useEffect(() => {
        if (order.Contract?.Id === contract?.Id) {
            setDisavleAdd(false);
        } else {
            setDisavleAdd(true);
        }
    }, [order, contract]);

    return (
        <>
            <Card className={classes.root} >
                {showRest ? <Chip color="warning" size="small" variant="outlined" className={classes.rest} label={`${item.Rest} : ${item.Reserve}`} /> : null}
                {item.Prices.length > 0 &&
                    <Fab
                        className={classes.buy}
                        disabled={disableAdd}
                        color="warning"
                        aria-label="add to shopping cart"
                        onClick={handleAddToShoppingCart}
                    >
                        <AddShoppingCartIcon />
                    </Fab>
                }

                <CardActionArea className={classes.action_top} onClick={() => history.push(`/catalog/item/${item.Code}`)} >
                    {item.Image !== '' ?
                        <CardMedia className={classes.media}
                            image={`${host}assets/files/images/prods/${item.Image}`}
                            title={item.Name}
                        /> :
                        <Typography display='block' align='center' className={classes.media}>
                            <span className={clsx(`mdi ${categoryImg === '' ? 'mdi-shimmer' : categoryImg.toLowerCase()}`, classes.cat_img)}></span>
                        </Typography>}
                </CardActionArea>

                <CardContent className={classes.content}>
                    <Typography variant="caption">Арт. </Typography>
                    <Typography variant="button" >{item.Article}</Typography>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{ mb: 1 }}
                    >
                        <Typography>
                            <Typography component='span' variant="caption">Код: </Typography>
                            <Typography component='span' variant="button" >
                                {item.Code}
                            </Typography>
                        </Typography>

                        <Typography variant="body2"  >
                            {item.Vendor}
                        </Typography>
                    </Stack>

                    <Typography variant="body2" component="p" gutterBottom>
                        {item.Name}
                    </Typography>
                </CardContent>

                <CardActions sx={{ height: 70 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}

                    >
                        {
                            item.Prices.map(price => (
                                <Grid item key={price.Id} >
                                    {customerPriceType.Id === price.Id ?
                                        <Badge
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            badgeContent={0}
                                            color="secondary"
                                            overlap="rectangular"
                                        >
                                            <Typography color="primary" gutterBottom variant="h4" title={price.NameSite}>
                                                {parseInt(`${price.Value}`)}<span className={classes.superscript}>.{(Math.round(price.Value * 100) / 100).toFixed(2).split('.')[1]}</span>
                                                <span className={classes.subscript}>{price.Currency.Name}</span>
                                            </Typography>
                                        </Badge>
                                        :
                                        <span className={classes.priceRC} title={price.NameSite}>
                                            {parseInt(`${price.Value}`)}<span className={classes.superscript}>.{(Math.round(price.Value * 100) / 100).toFixed(2).split('.')[1]}</span>
                                            <span className={classes.subscript2}>{price.Currency.Name}</span>
                                        </span>
                                    }

                                </Grid>
                            ))
                        }
                    </Grid>
                </CardActions>
            </Card>
        </>
    );
};