import { ReactElement, useContext, useState, useEffect, useCallback } from 'react';
import { Theme, useTheme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useAuth } from 'oidc-react';
import { NavLink, useHistory } from 'react-router-dom';

import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { AppContext } from '../AppContext/AppContext';
import { Collapse, Chip, Grid, Box, Card, TextField, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, IconButton } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TablePagination from '@mui/material/TablePagination';

import { IOrder } from '../../interfaces/IOrder';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ClearIcon from '@mui/icons-material/Clear';

import { format, add, intervalToDuration } from 'date-fns';
import { ICustomer } from '../../interfaces/ICustomer';
import { IsAdmin } from '../../helpers/utils';


const host = process.env.REACT_APP_IMG_ROOT;

const localeMap = {
    uk: ukLocale,
    en: enLocale,
    ru: ruLocale,
};

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',

}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', p: 0 }} />}
        {...props}
    />
))(({ theme }) => ({
    // backgroundColor:
    //     theme.palette.mode === 'dark'
    //         ? 'rgba(255, 255, 255, .05)'
    //         : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface IResponseOrders {
    Total: number
    Orders: IOrder[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },
    })
);

const startDayMonth = () => {
    const now = new Date();
    const yN = now.getFullYear();
    const mN = now.getMonth();
    // const dN = now.getDate();
    return new Date(yN, mN, 1);
};

const lastDayMonth = () => {
    const now = new Date();
    const yN = now.getFullYear();
    const mN = now.getMonth();
    return new Date(yN, mN + 1, 0);
};


const distanceDays = (date: Date, days: number) => {
    const now = new Date();
    const d = new Date(date);
    const end = add(d, { days: days });
    const Difference_In_Time = now.getTime() - end.getTime();
    return parseInt(Math.ceil(Difference_In_Time / (1000 * 3600 * 24)).toFixed(0));
};


const leftDays = (date: Date, days: number) => {
    const dd: number = distanceDays(date, days);

    if (dd < 0) {
        return `дійсний ще ${Math.abs(dd)} дн`;
    } else if (dd === 0) {
        return 'дійсний сьгодні';
    } else {
        return `просрочено ${Math.abs(dd)} дн`;
    }


};


const colorsDays = (date: Date, days: number) => {
    const dd: number = distanceDays(date, days);

    if (dd < -1) {
        return 'success';
    } else if (dd === -1 || dd === 0) {
        return 'warning';
    } else if (dd > 0) {
        return 'error';
    }
    return 'default';
};

type Props = {}

export const Orders = (props: Props) => {
    const classes = useStyles();
    const auth = useAuth();
    const theme = useTheme();
    const history = useHistory();
    const { populateOrder, contact } = useContext(AppContext);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [orderExp, setOrderExp] = useState<IOrder | null>(null);

    const [expanded, setExpanded] = useState<string | false>('');
    const [locale, setLocale] = useState<keyof typeof localeMap>('uk');

    const [isAdmin, setIsAdmin] = useState(false);
    const [filter, setFilter] = useState(false);
    const [startDay, SetStartDay] = useState<Date>(startDayMonth());
    const [lastDay, SetLastDay] = useState<Date>(lastDayMonth());
    const [customerFilter, setCustomerFilter] = useState<ICustomer | undefined>(contact?.Customer);

    const handleDefaultPeriod = () => {
        SetStartDay(startDayMonth());
        SetLastDay(lastDayMonth());
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (id: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? id : false);

        if (newExpanded) {
            fetchOrder(id);
        }
    };

    const handleCustomerFilter = () => {
        if (contact) {
            setCustomerFilter(contact.Customer);
        }
    };

    const handleClearCustomerFilter = () => {
        setCustomerFilter(undefined);
    };

    const fetchOrder = useCallback(async (id: string) => {
        if (!auth.userData) {
            return;
        }

        const url = `/api/v1.0/order/load/${id}`;
        const token = `Bearer ${auth.userData.access_token}`;
        setOrderExp(null);
        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setOrderExp(data as IOrder);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });

    }, [auth.userData]);

    const editOrder = useCallback(async (id: string) => {
        if (!auth.userData) {
            return;
        }

        const url = `/api/v1.0/order/load/${id}`;
        const token = `Bearer ${auth.userData.access_token}`;

        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    populateOrder(data as IOrder);
                    history.push('/order');
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });

    }, [auth.userData]);


    const fetchData = useCallback(async (token: string, pg: number, rpr: number, start: Date, end: Date, ClientID: string | undefined) => {
        const Completed: boolean = true;

        const url = `/api/v1.0/orders?search=${search}&Page=${pg + 1}&PageSize=${rpr}&Completed=${Completed}&DateStart=${format(start, 'yyyy-MM-dd')}&DateEnd=${format(end, 'yyyy-MM-dd')}${ClientID ? `&ClientID=${ClientID}` : ''}`;
        // console.log(url);

        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data as IResponseOrders) {
                    // console.log(data);
                    setTotal(data.Total);
                    setOrders(data.Orders);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }, []);

    useEffect(() => {
        if (contact?.Customer) {
            setCustomerFilter(contact?.Customer);
        }

    }, [contact?.Customer]);

    useEffect(() => {
        if (!auth.userData) {
            return;
        }

        // console.log(auth.userData, contact);

        if (auth.userData) {
            const roles = auth.userData.profile.role as string[];
            const isAdmin = IsAdmin(roles, 'Administrator');
            setIsAdmin(isAdmin);
            // if (!roles) {
            //     setIsAdmin(false);
            // } else {
            //     if (Array.isArray(roles)) {
            //         const idx = roles.findIndex((x) => x === 'Administrator');
            //         setIsAdmin(idx !== -1);
            //     } else {
            //         setIsAdmin(roles === 'Administrator');
            //     }
            // }
        }

        let ClientID;
        if (customerFilter) {
            ClientID = customerFilter.Id;
        }

        fetchData(`Bearer ${auth.userData.access_token}`, page, rowsPerPage, startDay, lastDay, ClientID);

    }, [auth.userData, fetchData, page, rowsPerPage, startDay, lastDay, customerFilter]);

    const formatDateOrder = (date: Date) => {
        const now = new Date();
        const d = new Date(date);

        const yN = now.getFullYear();
        const mN = now.getMonth();
        const dN = now.getDate();

        const yD = d.getFullYear();
        const mD = d.getMonth();
        const dD = d.getDate();

        if (yN === yD && mN === mD && dN === dD) {
            return format(d, 'HH:mm');
        } else {
            return format(d, 'dd.MM.yyyy');
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]} >
            <div className={classes.root}>
                <Card className='it-box'>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item >
                                <Typography variant='h5' sx={{ pb: 2 }}>Історія замовлень</Typography>
                            </Grid>
                            <Grid item >
                                <Chip
                                    sx={{ mt: 1 * 0.75 }}
                                    label={`Період: ${format(startDay, 'dd.MM.yyyy')} - ${format(lastDay, 'dd.MM.yyyy')}`}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setFilter(!filter)}
                                    onDelete={handleDefaultPeriod}
                                />
                                {/* <IconButton onClick={() => setFilter(!filter)} >
                                    <FilterAltOutlinedIcon />
                                </IconButton> */}
                            </Grid>
                        </Grid>
                        <Collapse in={filter} timeout="auto">
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={2}>
                                    <DesktopDatePicker
                                        label="Початок періоду"
                                        inputFormat="dd.MM.yyyy"
                                        value={startDay}
                                        onChange={(newValue) => SetStartDay(newValue as Date)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <DesktopDatePicker
                                        label="Кінець періоду"
                                        inputFormat="dd.MM.yyyy"
                                        value={lastDay}
                                        onChange={(newValue) => SetLastDay(newValue as Date)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                {isAdmin &&
                                    <Grid item xs={6} sm={4}>
                                        <Button variant='outlined' onClick={handleCustomerFilter} sx={{ mr: 1 }}>Лише мої</Button>
                                        <IconButton onClick={handleClearCustomerFilter} title="Усі замовлення">
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Collapse>

                        {/* <Collapse in={!filter} timeout="auto" unmountOnExit >
                            <Grid container spacing={2}>
                                {isAdmin && customerFilter &&
                                    <Grid item>
                                        <Chip label={`Покупець: ${customerFilter ? customerFilter.Name : ''}`} size="small" variant="outlined" onDelete={handleClearCustomerFilter} />
                                    </Grid>
                                }
                            </Grid>
                        </Collapse> */}


                        <Box sx={{ mb: 1 }} >
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <TablePagination
                                        component="div"
                                        count={total}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>

                            {
                                orders.map(order => (
                                    <Accordion key={order.Id} expanded={expanded === order.Id} onChange={handleChange(order.Id)}>
                                        <AccordionSummary
                                            aria-controls={`${order.Id}-content`}
                                            id={`${order.Id}-header`}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    {order.Completed ? <TaskAltIcon sx={{ fontSize: 16, mt: 1 / 2 }} color="success" /> : <DriveFileRenameOutlineIcon sx={{ fontSize: 16, mt: 1 / 2 }} />}
                                                </Grid>
                                                <Grid item xs={5} sm={'auto'}>
                                                    {order.Number}
                                                </Grid>
                                                <Grid item xs={5} sm={1} sx={{ textAlign: 'right' }}>
                                                    {formatDateOrder(new Date(order.Date))}
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    {order.RelatedDocs > 0 || order.Confirmed
                                                        ? null
                                                        :
                                                        <Chip
                                                            size="small"
                                                            icon={<AccessTimeIcon />}
                                                            label={leftDays(order.Date, order.Reserve)}
                                                            variant="outlined"
                                                            color={colorsDays(order.Date, order.Reserve)}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid item xs={7} sm={2} md={1} sx={{ textAlign: 'right' }} >
                                                    {order.OrderTotal === 0 ? '' : <>{(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)} <small>{order.CurrencyName.toLocaleLowerCase()}</small></>}
                                                </Grid>

                                                <Grid item xs={9} sm={5} md={6}>
                                                    <Typography display='block'>
                                                        {order.Customer?.Name}
                                                    </Typography>
                                                    <Typography display='block' variant='caption'>
                                                        {order.Contract?.Name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ textAlign: 'right' }}>
                                                    {order.RelatedDocs > 0 || order.Confirmed
                                                        ?
                                                        <IconButton
                                                            aria-label="readonly_order"
                                                            color="inherit"
                                                            size='small'
                                                            title='Тільки для перегляду'
                                                        >
                                                            <LockOutlinedIcon />
                                                        </IconButton>
                                                        : <IconButton
                                                            aria-label="edit_order"
                                                            color="inherit"
                                                            size='small'
                                                            onClick={() => editOrder(order.Id)}
                                                            title='Редагувати'
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>


                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {!orderExp && <Typography display='block' variant='h5' align='center' >Loading...</Typography>}
                                            {orderExp &&
                                                <Box pl={2} >
                                                    <Grid container spacing={2} sx={{ py: 1, borderBottom: '1px solid #eee', fontWeight: 'bold', fontSize: '.9em' }} >
                                                        <Grid item sx={{ minWidth: 50 }}>#</Grid>
                                                        <Grid item sx={{ width: 60 }}></Grid>
                                                        <Grid item sx={{ width: 110 }}>
                                                            <div>Код</div>
                                                            <div><small>Арт.</small></div>
                                                        </Grid>
                                                        <Grid item xs={6} sm={5}>Найменування</Grid>
                                                        <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>К-сть</Grid>
                                                        <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>Ціна</Grid>
                                                        <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>Сума</Grid>
                                                    </Grid>

                                                    {orderExp.Rows.map((row) => (
                                                        <Grid key={row.LineNumber} container spacing={2} sx={{ py: 1, borderBottom: '1px solid #eee' }} >
                                                            <Grid item sx={{ minWidth: 50 }}>{row.LineNumber}</Grid>

                                                            <Grid item sx={{ width: 60 }}>
                                                                {row.ProductImage !== '' &&
                                                                    <img style={{ width: '100%' }} src={`${host}assets/files/images/prods/${row.ProductImage}`} alt={row.ProductCode} />
                                                                }
                                                            </Grid>

                                                            <Grid item sx={{ width: 110 }}>
                                                                <Typography display='block'>{row.ProductCode}</Typography>
                                                                <Typography display='block' variant='caption' >{row.ProductArticle}</Typography>
                                                            </Grid>

                                                            <Grid item xs={6} sm={5}>{row.ProductName}</Grid>
                                                            <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>{row.Qty}</Grid>
                                                            <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>{(Math.ceil(row.SalePrice * 100) / 100).toFixed(2)}</Grid>
                                                            <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>{(Math.ceil(row.Total * 100) / 100).toFixed(2)}</Grid>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </Box>

                        <TablePagination
                            sx={{ mt: 1 }}
                            component="div"
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />


                    </CardContent>
                </Card>
            </div>
        </LocalizationProvider>
    );
};
