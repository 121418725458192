import React, { useEffect, useContext, useState } from 'react';
import { Theme, useTheme, styled, alpha } from '@mui/material/styles';
import { NavLink, useHistory } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Stack, AppBar, Toolbar, InputBase, InputAdornment, Button, Badge, IconButton, Box, Menu, MenuItem, Typography, Tooltip, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from 'oidc-react';
// import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import Categories from './Categories';
import { AppContext } from '../AppContext/AppContext';
import { IContact } from '../../interfaces/IContact';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CheckIcon from '@mui/icons-material/Check';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { config } from '../../config';
import { IContract } from '../../interfaces/IContract';
import { HtmlTooltip } from '../Extention/HtmlTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    brand: {
      height: 32,
      // textShadow: '0 6px 6px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.2)'
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },

    searchbox: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#0857a9',
      // color: '#fff',
      width: 270,
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
    },

    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      // color: '#fff'
    },
    iconButton: {
      padding: 10,
      // color: '#bbd5f2',
    },
    iconLogin: {
      // color: '#bbd5f2',
    },


    divider: {
      height: 28,
      margin: 4,
      // backgroundColor: '#1a75d2'
    },
  })
);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 2,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const badgeStyle = {
  '& .MuiBadge-badge': {
    fontSize: '.5em'
  }
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: (theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.03) : alpha(theme.palette.common.black, 0.3)),
  '&:hover': {
    backgroundColor: (theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.06) : alpha(theme.palette.common.black, 0.6)),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  // color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

export default function AppNavigator() {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { order, contact, contract, isDarkTheme,
    populateContact, populateContract, setDarkMode,
    createOrder
  } = useContext(AppContext);
  // const [connection, setConnection] = useState<HubConnection>();

  const [data, setCategories] = React.useState({ categories: [] });
  const [search, setSearch] = React.useState('');

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
  const openComMenu = Boolean(anchorElMenu);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const login = () => {
    auth.signIn();
  };

  const logout = () => {
    populateContact(null);
    auth.signOut().then(() => {
      history.push('/');
    })
      .catch((error) => {
        console.log('Error', error);
      });

  };

  const searchProducts = () => {
    if (search === '') {
      return;
    }
    history.push(`/search/${search}`);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      searchProducts();
    }
  };

  const handleSearchProducts = () => {
    searchProducts();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMenuCom = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenuCom = () => {
    setAnchorElMenu(null);
  };

  const handleSelectContract = (id: string) => {
    if (contact) {
      const selected: IContract | undefined = contact.Customer.Contracts.find(item => item.Id === id);
      if (selected) {
        populateContract(selected);
      }
    }
  };

  const handleThemeMode = () => {
    setDarkMode(!isDarkTheme);
  };

  useEffect(() => {
    if (!auth) {
      return;
    }

    const fetchData = async (token: string) => {
      const urlCategory = '/api/v1.0/category';
      const urlContact = '/api/v1.0/contact';
      const url = `/orderHub`;
      await fetch(urlCategory, { headers: { authorization: `Bearer ${token}`, accept: 'Accept: application/json' } })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            // console.log('fetch categories', data);
            setCategories({ categories: data });
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });

      await fetch(urlContact, { headers: { authorization: `Bearer ${token}`, accept: 'Accept: application/json' } })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const _contact = data as IContact;

            // console.log('contact', _contact);
            populateContact(_contact);
            if (_contact.Customer && _contact.Customer.Contracts.length > 0) {
              populateContract(_contact.Customer.Contracts[0]);
            }
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });

      // const con = new HubConnectionBuilder()
      //   .withUrl(url, { accessTokenFactory: () => token })
      //   .withAutomaticReconnect()
      //   .build();

      // con.on('ReceiveMessage', (_user, _message) => {
      //   //const mes = JSON.parse(_message);
      //   console.log('ReceiveMessage', _message);


      // });
      // con.on('ReceiveSystemMessage', (_user, _message) => {
      //   console.log('ReceiveSystemMessage', _message);
      //   // setSystemMessType(_message);
      //   // setJoined(_user);        
      // });

      // await con.start();

      // setConnection(con);
    };

    if (auth && auth.userData) {
      fetchData(auth.userData.access_token);
    }
  }, [auth]);

  return (
    <>
      <AppBar position="static">
        <Toolbar id='back-to-top-anchor' variant='dense' className={classes.toolbar}>

          <Button
            component={NavLink}
            to='/'
            sx={{ mr: 1 }}
          >
            <StyledBadge
              badgeContent={config.ENVIRONMENT?.substring(0, 3)}
              showZero
              sx={badgeStyle}
            >
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img width='100%' height='auto' alt="IT-Link" className={classes.brand} src="/assets/files/images/logo.svg" />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img alt="IT-Link" className={classes.brand} src="/assets/files/images/logo-xs.svg" />
              </Box>
            </StyledBadge>
          </Button>

          {/* Sear section */}
          <Search >
            <SearchIconWrapper>
              <IconButton aria-label="search-products">
                <SearchIcon />
              </IconButton>
            </SearchIconWrapper>
            <StyledInputBase
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Пощук..."
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size='small'
                    aria-label="search_products"
                    onClick={handleSearchProducts}
                    edge="end"
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Search>

          <Box sx={{ flexGrow: 1 }} />

          {auth.userData && contact &&
            <>
              <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 / 2 }}>{contact.Name}</Typography>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 / 2 }}>

                <HtmlTooltip
                  title={
                    order.Rows.length > 0 && order.Contract?.Id !== contract?.Id ?
                      <React.Fragment>
                        <b>{'Договір в замовленні інший чим в каталозі!'}</b>
                        <p>{'Католог тільки для перегляду.'}</p>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {'Список договорів'}
                      </React.Fragment>
                  }
                >
                  <Button sx={{ mr: 1 }}
                    aria-label="customer-contracts"
                    startIcon={order.Rows.length > 0 && order.Contract?.Id !== contract?.Id ? <PriorityHighIcon /> : <PlaylistAddCheckIcon />}
                    onClick={handleOpenMenu}
                    color={order.Rows.length > 0 && order.Contract?.Id !== contract?.Id ? 'warning' : 'inherit'}
                  >
                    {contract && <>{contract.Name}</>}
                  </Button>
                </HtmlTooltip>

                <Button
                  component={NavLink}
                  sx={{ mr: 1, color: 'inherit' }}
                  aria-label="orders"
                  startIcon={<ManageHistoryIcon />}
                  to='/orders'
                >
                  Замовлення
                </Button>
                <Button
                  component={NavLink}
                  sx={{ color: 'inherit' }}
                  aria-label="orders"
                  startIcon={<HistoryIcon />}
                  to='/payments'
                >
                  Оплати
                </Button>
              </Box>
            </>
          }

          {auth.userData && order &&
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <Tooltip title='Нове замовлення'>
                <IconButton
                  aria-label="new_order"
                  color="inherit"
                  onClick={createOrder}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Загалом ∑ ${(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)}`}>
                <IconButton
                  aria-label="current_order"
                  color="inherit"
                  component={NavLink}
                  to='/order'
                >
                  <StyledBadge badgeContent={order.Rows.length} showZero color="warning">
                    <ShoppingCartOutlinedIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
            </Box>
          }

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {auth.userData ?
              <IconButton aria-label="logout" onClick={logout} color="inherit">
                <ExitToAppIcon />
              </IconButton>
              :
              <Button className={classes.iconLogin} title="Login"
                aria-label="login"
                onClick={login}
                startIcon={<PersonOutlineIcon />}>
                Вхід
              </Button>
            }
            <IconButton
              color="inherit"
              aria-label="theme"
              onClick={handleThemeMode}
            >
              {isDarkTheme ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <IconButton
              onClick={handleOpenMenuCom}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>

        </Toolbar>

        {auth.userData && contact && <>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ display: { md: 'none' }, mr: 1 }}
          >
            <Typography variant="body2" sx={{ pt: 1 }}>{contact.Name}</Typography>

            <IconButton
              title="Contract"
              color={order.Rows.length > 0 && order.Contract?.Id !== contract?.Id ? 'warning' : 'inherit'}
              onClick={handleOpenMenu}
            >
              <PlaylistAddCheckIcon />
            </IconButton>

            <IconButton
              sx={{ color: 'inherit' }}
              component={NavLink}
              aria-label="orders"
              to='/orders'
            >
              <ManageHistoryIcon />
            </IconButton>

            <IconButton
              sx={{ color: 'inherit' }}
              component={NavLink}
              aria-label="orders"
              to='/payments'
            >
              <HistoryIcon />
            </IconButton>

            <IconButton
              aria-label="new_order"
              color="inherit"
              onClick={createOrder}
            >
              <FileCopyOutlinedIcon />
            </IconButton>

            {order &&
              <IconButton
                aria-label="menu_order"
                color="inherit"
                component={NavLink}
                to='/order'
              >
                <StyledBadge badgeContent={order.Rows.length} showZero color="warning">
                  <ShoppingCartOutlinedIcon />
                </StyledBadge>
              </IconButton>
            }

          </Stack>
        </>}

        {auth && auth.userData && <Categories categories={data.categories} />}
      </AppBar>

      {contact &&
        <Menu
          anchorEl={anchorEl}
          id="customer-contracts-menu"
          open={openMenu}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {contact.Customer.Contracts.map((item) => (
            <MenuItem key={item.Id} onClick={(e) => handleSelectContract(item.Id)}>
              {contract && contract.Id === item.Id ? <CheckIcon sx={{ mr: 1 }} /> : null} {item.Name}
            </MenuItem>
          ))}

        </Menu>
      }


      <Menu
        anchorEl={anchorElMenu}
        id="common-contracts-menu"
        open={openComMenu}
        onClose={handleCloseMenuCom}
        onClick={handleCloseMenuCom}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {auth.userData ?
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>Вихід</ListItemText>
          </MenuItem>
          :
          <MenuItem onClick={login}>
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText>Вхід</ListItemText>
          </MenuItem>
        }
        <MenuItem onClick={handleThemeMode}>
          <ListItemIcon>
            {isDarkTheme ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
          </ListItemIcon>
          <ListItemText>
            {isDarkTheme ? <span>Світла тема</span> : <span>Темна тема</span>}
          </ListItemText>
        </MenuItem>
      </Menu>


    </>
  );
}
